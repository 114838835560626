.App {
  text-align: center;
}

.vcenter {
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
  justify-content: center;
}

.textshadow {
  text-shadow: 1px 0px 0px black;
}

.navbar-padding {
  padding-top: 56px;
}